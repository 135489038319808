@font-face {
  font-family: myFirstFont;
  src: url(Georgia.ttf);
}
h1, h2, h3, h4, h5, p, span, a, li {
  font-family: myFirstFont;
}
.App {
  text-align: center;
  background-color: #E2E9F8;
  padding: 30px 35px 35px 35px;
}
.header > ul {
  display: inline-flex;
  list-style: none;
}
.header > ul > li > a {
  margin-right: 20px;
  color: #3fb0d1;
  text-decoration: none;
}
.Icon {
  width: 32px;
  margin-right: 10px;
}
.footer {
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  width: 100%;
  border-radius: 24px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
}
.App-logo {
  height: 66px;
  pointer-events: none;
  margin-bottom: 20px;
}

.App-contact-container {
  display: flex;
  align-items: center;
}

.App-countdown-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 120px;
}
.custom-style {
  height: 60px!important;
  margin-bottom: 20px;
}
.button-store-name {
  font-size: inherit!important;
}
.App-countdown {
  box-shadow: 0 14px 80px rgba(43, 27, 63, 0.09), 0 3.12708px 17.869px rgba(43, 27, 63, 0.0536497), 0px 0.931014px 5.32008px rgba(43, 27, 63, 0.0363503);
  border-radius: 8px;
  height: 109px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 0;
  margin-right: -10px;
}
h4 {
  font-size: 1.3rem!important;
}
.app-row {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}
.AppShow-Container {
  background: rgb(102,103,171);
  background: linear-gradient(0deg, rgba(102,103,171,1) 0%, rgba(56,190,217,1) 100%);
  border-radius: 40px;
  height: 100%;
  padding: 60px 20px 20px;
}

.AppContent {
  text-align: left;
}
.App-container {
  background: linear-gradient(0deg, rgb(68, 56, 94, 0.6) 0%,rgb(238, 165, 156, 0.6) 100%);
  width: 100%;
  max-width: 100%;
  border-radius: 24px;
  padding: 50px 40px;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (width: 412px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
}


@media screen and (width: 390px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 375px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 428px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 414px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 810px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 384px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 360px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 393px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}


@media screen and (width: 820px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}

@media screen and (width: 768px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}

@media screen and (width: 540px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}

@media screen and (width: 280px){
  .AppShow-Container {
    padding: 0 !important;
    padding-top: 25px!important;
    margin-top: 20px;
  }
  .App-logo {
    margin-bottom: 20px!important;
  }
  .App-countdown {
    text-align: center!important;
    margin-bottom: 10px;
  }

  .input {
    width: inherit !important
  }
  .footer {
    width: inherit!important;
  }
  .App-container {
    padding: 0!important;
  }
  .AppContent {
    margin-top: 20px;
  }
  .header > ul > li > a {
    font-size: 12px;
  }
  h1 {
    font-size: 20px!important;
  }
  h2 {
    font-size: 18px!important;
  }
  p {
    font-size: 15px!important;
  }
  .appAbout{
    text-align: left!important;
  }
}
